<template>
    <el-timeline>
        <el-timeline-item v-for="(item, index) in records" :key="index" :timestamp="item.endTimeLocal" placement="top">
            <el-card>
                <h4>{{item.node.name}}</h4>
                <p>审核意见:{{ item.data.reviewNote }}</p>
                <!-- <span v-html="item.content"></span>-->
                <p v-if="item.outFlag === 'Reject'">{{ item.handlerName }} 驳回至 {{ item.data.rejectNext }} {{ item.endTimeLocal }}</p>
                <p v-else>{{ item.handlerName }} {{ item.endTimeLocal }}</p>
            </el-card>
        </el-timeline-item>
    </el-timeline>
</template>

<script>
export default {
    data() {
        return {
            userData: [],
        };
    },
    props: {
        instance: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    computed: {
        records() {
            return this.instance.records.map(e => {
                /*if (e.data && e.data.reviewNote) {
                        let content = '<p v-if="item.data.reviewNote">审核意见:' + e.data.reviewNote + '</p>';
                        e.content = content.replace(/\n/gm, '<br/>');
                    } else {
                        e.content = '<p v-if="item.data.reviewNote">审核意见:</p>';
                    }*/
                e.endTimeLocal = '未提交';
                if (e.endTime) {
                    e.endTimeLocal = ': ' + this.$dayjs.tz(e.endTime, 'UTC').tz(this.$dayjs.tz.guess()).format('YYYY-MM-DD HH:mm');
                }
                let user = this.userData.filter(item => item.username === e.handler);
                e.handlerName = user.length > 0 ? user[0].realName : e.handler;
                return e;
            }).sort((a, b) => a.endTime < b.endTime ? 1 : -1);
        }
    },
    methods: {
        async loadDic() {
            const data = await Promise.all([
                this.$client.getSysUserByName(''),
            ]);
            this.userData = data[0].list || [];
        }
    },
    mounted() {
        this.loadDic();
    }
};
</script>
