<template>
    <div>
        <div class="form-table">
        <!--信息展示组件-->
        <flow-overhaul-detail v-if="showFlowOverhaulDetail" ref="ref_flowOverhaul_detail"></flow-overhaul-detail>
        <overhaul-create v-if="showOverhaulApply" ref="ref_overhaul_apply"></overhaul-create>
        <overhaul-receive v-if="showOverhaulReceive" ref="ref_overhaul_receive"></overhaul-receive>

        <record-history v-if="showRecordHistory" :instance="currentData"/>
        <!--end-->
        </div>
        <div class="submit-btn-group button-fixed">
            <el-button @click="closeForm">关闭</el-button>
        </div>
    </div>
</template>

<script>
/**
 * 通用信息展示组件
 */
import FlowOverhaulDetail from '@/pages/overhaul/overhaulDetail';
import OverhaulCreate from '@/pages/overhaul/overhaulCreate.vue';
import OverhaulReceive from '@/pages/overhaul/overhaulReceive.vue';
import RecordHistory from '@/components/workflow/RecordHistory';
export default {
    components: {
        FlowOverhaulDetail,
        OverhaulCreate,
        OverhaulReceive,
        RecordHistory
    },
    data() {
        return {
            currentData: {},
            showFlowOverhaulDetail: false,
            showOverhaulApply: false,
            showOverhaulReceive: false,
            showRecordHistory: false
        };
    },
    created() {
        this.init(JSON.parse(sessionStorage.getItem(this.$route.query.id)));
    },
    methods: {
        init(item) {
            this.currentData = item;
            this.showRecordHistory = true;
            this.showFlowOverhaulDetail = true;
            this.$nextTick(() => {
                this.$refs.ref_flowOverhaul_detail.init(item);
            });
            // switch (item.curNode.code) {
            //     case 'elec_overhaul_planned_receipt':
            //         this.showOverhaulReceive = true;
            //         this.$nextTick(() => {
            //             this.$refs.ref_overhaul_receive.init(item);
            //         });
            //         break;
            //     case 'elec_overhaul_planned_first_review':
            //     case 'elec_overhaul_planned_second_review':
            //     case 'elec_overhaul_planned_third_review':
            //     case 'elec_overhaul_planned_fourth_review':
            //     case 'elec_overhaul_planned_fifth_review':
            //         this.showFlowOverhaulDetail = true;
            //         this.$nextTick(() => {
            //             this.$refs.ref_flowOverhaul_detail.init(item);
            //         });
            //         break;
            //     case 'elec_overhaul_planned_apply':
            //         this.showOverhaulApply = true;
            //         this.$nextTick(() => {
            //             this.$refs.ref_flowOverhaul_detail.init(item);
            //         });
            //         break;
            //     default:
            //         break;
            // }
        },
        closeForm(type) {
            //this.$emit('closeForm', type);
            this.$root.$emit('fe-framework:tab:close', this.$route.fullPath);
        },
        clickStep(index) {
            //this.$emit('clickStep', index);

            let status = this.currentData.businessFormData.phaseStatus;
            switch (index) {
                case 0:
                    this.showBusinessApplyShow = true;
                    this.showReconnaissanceShow = false;
                    this.showElectricPlanShow = false;
                    this.showCompletionApplyShow = false;
                    this.showCompletionAcceptanceShow = false;
                    break;
                case 1:
                    if (status >= '10') {
                        this.showReconnaissanceShow = true;
                        this.showBusinessApplyShow = false;
                        this.showElectricPlanShow = false;
                        this.showCompletionApplyShow = false;
                        this.showCompletionAcceptanceShow = false;
                    }
                    break;
                case 2:
                    if (status >= '20') {
                        this.showElectricPlanShow = true;
                        this.showBusinessApplyShow = false;
                        this.showReconnaissanceShow = false;
                        this.showCompletionApplyShow = false;
                        this.showCompletionAcceptanceShow = false;
                    }
                    break;
                case 3:
                    if (status >= '30') {
                        this.showCompletionAcceptanceShow = true;
                        this.showBusinessApplyShow = false;
                        this.showReconnaissanceShow = false;
                        this.showElectricPlanShow = false;
                        this.showCompletionApplyShow = false;
                    }
                    break;
                default:
                    break;
            }
        }
    }
};
</script>
<style>
    .button-fixed {
        margin:  0 !important;
    }
    .submit-btn-group {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 60px;
    }
    .form-table {
        height: calc(100vh - 170px);
        margin-bottom: 10px;
        overflow-y: scroll;
    }
</style>
